/** @jsx jsx */
import { Box, jsx } from "theme-ui";
import React, { useCallback, useState, useEffect } from "react";
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import { useQueryParam, StringParam } from "use-query-params";
import DonationLayout from "../layout/DonationLayout";
import { eventCallbackTriggers } from "../templates/wppage";
import banner from "../../static/imgs/general-fund-group.png";

const GeneralFund = () => {
  const [amountId] = useQueryParam("amountId", StringParam);
  const [paramDonationAmount, setParamDonationAmount] = useState();

  const pageContent = (id, modalTitle, btnAmount) => {
    const titletext = modalTitle || "Heartfulness General Fund";
    return `<gatsby_donation donationId="${id}" btntext="${titletext}" amount='${btnAmount}'
    btnBg=#ea5232 colortext = navy colorprimary = #085788
    colorsecondary = midnightblue colorbackground = white
    colormuted = #f6f6f6 colorhighlight = #efeffe titletext =
    "Heartfulness General Fund" />`;
  };

  const PageContent = React.memo(PageContentNonMemoized);

  const eventCallback = useCallback(
    (event) => eventCallbackTriggers("Heartfulness_General_Fund", event),
    []
  );

  const [isMobile, setIsMobile] = useState(false);

  const windowSizeTracker = () => {
    return window.innerWidth < 975 ? setIsMobile(true) : setIsMobile(false);
  };

  useEffect(() => {
    windowSizeTracker();
  }, []);

  useEffect(() => {
    const donationAmountString = {
      1: 1250000,
      2: 1000000,
      3: 200000,
      4: 10000,
    };

    setParamDonationAmount(donationAmountString[amountId]);
  }, [amountId]);

  return (
    <>
      <DonationLayout
        seoData={{ title: "Heartfulness General Fund" }}
        isGeneralFund
      >
        <div className="banner-gatsby">
          <Box
            sx={{
              height: "331px",
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundImage: `url(${banner})`,
            }}
          />
          <div
            sx={{
              textAlign: "center",
              padding: "8px 15px",
              background: " #F8F8F9DE 0% 0% no-repeat padding-box",
            }}
          >
            <h2
              sx={{
                color: "#085788",
                fontWeight: "600",
                padding: "10px 0px 5px 0px",
              }}
            >
              Heartfulness - One Humanity. One Heart.
            </h2>
            <div
              style={{
                padding: isMobile ? "0px 20px" : "0px 95px",
                textAlign: isMobile && "left",
              }}
            >
              <p>
                Join Hearts and Hands with us as we take meditation to every
                Heart and every Home, free of charge. Thanks to the donations
                from our global community of practitioners we are developing and
                maintaining hundreds of facilities such as meditation centers,
                retreats, and research centers around the world besides
                thousands of local events to promote the benefits of Meditation
                and Yoga.{" "}
              </p>
              <p>
                {" "}
                Thank you for considering a donation to the Heartfulness
                Institute. Your contributions will help individuals find inner
                peace, clarity, and purpose in their lives.
              </p>
            </div>
            <div sx={{ fontSize: "20px", paddingBottom: "25px" }}>
              <Box>
                <PageContent
                  eventCallback={eventCallback}
                  pageContent={pageContent(
                    2,
                    amountId !== undefined
                      ? `${`INR ${paramDonationAmount} - `}Contribute Now`
                      : "Contribute Now",
                    paramDonationAmount
                  )}
                />
              </Box>
            </div>
          </div>
        </div>
        <div
          sx={{
            width: "100%",
            padding: "20px 10px",
            textAlign: "center",
            background: "#005ec1",
            color: "#FFFFFF",
          }}
        >
          <div className="container">
            <p className="q-txt" sx={{ fontSize: "16px" }}>
              For all queries and interests to contribute / initiate General
              Fund initiatives in collaboration with Heartfulness Institute,
              please write to
            </p>
            <p sx={{ fontSize: "22px", margin: "15px 0 0", color: "#FFFFFF" }}>
              <a sx={{ color: "white" }} href="mailto:info@heartfulness.org">
                info@heartfulness.org
              </a>
            </p>
          </div>
        </div>
      </DonationLayout>
    </>
  );
};

export default GeneralFund;
